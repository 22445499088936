import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCUD33nZBk6zaKU-7guUatoqVuid72WglI",
  authDomain: "nat-s-international.firebaseapp.com",
  databaseURL:
    "https://nat-s-international-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "nat-s-international",
  storageBucket: "nat-s-international.appspot.com",
  messagingSenderId: "784537069469",
  appId: "1:784537069469:web:a406dc2b41fffda7d77174",
  measurementId: "G-8CXYKN7QNS",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "NAT'S INTERNATIONAL"; //公司名稱
      },
      get regFormDomain() {
        return "https://nats-inter-ent.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
